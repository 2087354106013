import { HStack, Image, chakra, Text, VStack, Box } from "@chakra-ui/react";
import { render as toPlainText } from "datocms-structured-text-to-plain-text";
import { GatsbyImage } from "gatsby-plugin-image";
import { FC } from "react";
import { CustomIcon, InternalLink } from "src/atoms";
import { getBeenUpdated, getFormattedDates } from "src/services/article/date";
import { getReadTime } from "src/services/article/readTime";
import { getAuthorLink } from "src/services/author/link";
import { getReviewerLink } from "src/services/reviewer/link";

const SubHeader: FC<Pick<Gatsby.ArticleDataFragment, "date" | "meta" | "author" | "reviewer" | "body">> = ({
  date,
  meta,
  author,
  reviewer,
  body,
}) => {
  const { updatedAt } = getFormattedDates(date, meta);
  const beenUpdated = getBeenUpdated(date, meta);
  
  const readTime = getReadTime(toPlainText(body) || "", true);

  return (
    <Box
      display={{ base: "block", md: "none" }}
      mb="6"
    >
      <HStack
        alignItems="center"
        justifyContent="flex-start"
        my="6"
        spacing="4"
      >
        <HStack
          spacing="-10px"
        >
          {author?.image ? (
            <Image
              alt={author.image.alt || ""}
              as={GatsbyImage}
              border="2px solid"
              borderColor="white"
              height="9"
              image={author.image.gatsbyImageData}
              imgStyle={{
                overflow: "hidden",
                borderRadius: "50%",
              }}
              overflow="hidden"
              width="9"
            />
          ) : null}  

          {reviewer?.image ? (
            <Image
              alt={reviewer.image.alt || ""}
              as={GatsbyImage}
              border="2px solid"
              borderColor="white"
              borderRadius="50%"
              height="9"
              image={reviewer.image.gatsbyImageData}
              imgStyle={{
                overflow: "hidden",
                borderRadius: "50%",
              }}
              overflow="hidden"
              width="9"
            />
          ) : null}  
        </HStack>

        <VStack
          alignItems="flex-start"
          spacing="2"
        >
          {author ? (
            <Text
              color="grey.800"
              fontFamily="Mabry"
              fontSize="font-14"
              lineHeight="1"
            >
              <chakra.span>
                Publié par{' '}
              </chakra.span>

              <InternalLink
                style={{ textDecoration: "underline" }}
                to={getAuthorLink(author.slug!)}
              >
                {author.name}
              </InternalLink>
            </Text>
          ) : null}

          {reviewer ? (
            <Text
              color="grey.800"
              fontFamily="Mabry"
              fontSize="font-14"
              lineHeight="1"
            >
              <chakra.span>
                Relu par{' '}
              </chakra.span>

              <InternalLink
                style={{ textDecoration: "underline" }}
                to={getReviewerLink(reviewer.slug!)}
              >
                {reviewer.name}
              </InternalLink>
            </Text>
          ) : null}
        </VStack>
      </HStack>

      <HStack
        alignItems="center"
        padding="2"
        spacing="2"
      >
        {beenUpdated && meta ? (
          <>
            <CustomIcon name="history" />

            <Text
              color="grey.700"
              fontFamily="Mabry"
              fontSize="font-14"
              lineHeight="1"
            >
              Mis à jour le {updatedAt}
            </Text>
          </>
        ) : null}

        <CustomIcon name="clock" />

        <Text
          color="grey.700"
          fontFamily="Mabry"
          fontSize="font-14"
          lineHeight="1"
        >
          {readTime}
        </Text>
      </HStack>
    </Box>
  );
}

export default SubHeader;
