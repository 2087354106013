import { AccordionItem, AccordionButton, AccordionPanel, Text, Link, Image, HStack, VStack } from "@chakra-ui/react";
import { FC, Fragment } from "react";
import { CustomIcon, IconBox, InternalLink } from "src/atoms";

const MobileAccordionItem: FC<{ data: Gatsby.DatoCmsHeaderDropdown }> = ({ data }) => {
  const { title, sections, ctaLabel, ctaLink } = data;

  return (
    <AccordionItem border="none">
      {({ isExpanded }) => (
        <Fragment>
          <AccordionButton
            _hover={{ bg: "inherit" }}
            justifyContent="space-between"
            px="4"
            py="4.5"
          >
            <Text
              as="div"
              fontFamily="Mabry"
              fontSize="font-15"
              fontWeight="medium"
              lineHeight="none"
            >
              {title}
            </Text>

            <IconBox
              h="20px"
              w="20px"
            >
              <CustomIcon
                name="chevronDown"
                transform={`scale(1.25) ${isExpanded ? "rotate(-180deg)" : "rotate(0deg)"}`}
                transition="transform .2s ease-in-out"
              />
            </IconBox>
          </AccordionButton>

          <AccordionPanel p="0 32px 32px 32px">
            <VStack alignItems="flex-start" spacing="4">
              {sections?.filter((section) => Boolean(section.whiteBackground)).map((section) => {
                if (!section) {
                  return null;
                }

                if (section.buttons?.length) {
                  return (
                    <VStack alignItems="flex-start" spacing="4">
                      {section.buttons?.map((button, index) => {
                        if (!button) {
                          return null;
                        }
  
                        return (
                          <HStack
                            alignItems="center"
                            justifyContent="center"
                            key={button.label}
                            spacing="4"
                          >
                            {button.icon?.url ? (
                              <Image
                                alt={button.icon?.alt || ""}
                                height="16px"
                                src={button.icon?.url}
                                width="16px"
                              />
                            ) : null}
  
                            <Link
                              as={button.link?.startsWith("http") ? "a" : InternalLink}
                              color="grey.700"
                              display="block"
                              fontFamily="Mabry"
                              fontSize="font-15"
                              href={button.link}
                              key={button.label}
                              lineHeight="16px"
                              py="2"
                            >
                              {button.label}
                            </Link>
                          </HStack>
                        );
                      })}
                    </VStack>
                  );
                }

                if (!ctaLink && section.advertisements?.length > 0) {
                  const { title, link } = section.advertisements[0];

                  return (
                    <Link
                      alignItems="center"
                      as={link?.startsWith("http") ? "a" : InternalLink}
                      bg="grey.200"
                      display="flex"
                      justifyContent="space-between"
                      mt="32px !important"
                      p="5"
                      rounded="2xl"
                      to={link}
                      width="full"
                    >
                      <Text
                        color="grey.800"
                        fontFamily="Mabry"
                        fontSize="font-15"
                        lineHeight="20px"
                      >
                        {title}
                      </Text>

                      <CustomIcon
                        ml="2"
                        name="arrowRight"
                        transform="scale(1.4)"
                      />
                    </Link>
                  );
                }

                return null;
              })}

              {ctaLink ? (
                <Link
                  alignItems="center"
                  as={ctaLink?.startsWith("http") ? "a" : InternalLink}
                  bg="grey.200"
                  display="flex"
                  justifyContent="space-between"
                  mt="32px !important"
                  p="5"
                  rounded="2xl"
                  to={ctaLink}
                  width="full"
                >
                  <Text
                    color="grey.800"
                    fontFamily="Mabry"
                    fontSize="font-15"
                    lineHeight="20px"
                  >
                    {ctaLabel}
                  </Text>

                  <CustomIcon
                    ml="2"
                    name="arrowRight"
                    transform="scale(1.4)"
                  />
                </Link>
              ) : null}
            </VStack>
          </AccordionPanel>
        </Fragment>
      )}
    </AccordionItem>
  );
};

export default MobileAccordionItem;
