import { Box, Input, Button, BoxProps, InputProps, ButtonProps } from "@chakra-ui/react";
import { navigate } from "gatsby";
import { FC, useState } from "react";
import { CustomIcon } from "src/atoms";

const SearchInput: FC<
  {
    buttonLeft?: boolean;
    inputProps?: InputProps;
    buttonProps?: ButtonProps;
  } & BoxProps
> = (props) => {
  const [search, setSearch] = useState<string>("");

  const { placeholder, buttonLeft, inputProps, buttonProps, ...rest } = props;

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    navigate(`/result/?q=${search}`);
  };

  return (
    <Box position="relative" {...rest}>
      <form onSubmit={handleSubmit}>
        <Input
          _focus={{ outline: "none" }}
          _hover={{ borderColor: "text.secondary" }}
          _placeholder={{
            color: "grey.600",
            fontSize: "font-15",
            lineHeight: "tallest",
          }}
          border="1px"
          borderColor="grey.250"
          borderRadius="full"
          color="text.primary"
          fontSize="font-15"
          h="52px"
          lineHeight="tallest"
          onChange={(e) => setSearch(e.target.value)}
          p={buttonLeft ? "18px 24px 18px 50px" : "18px 50px 18px 24px"}
          placeholder={placeholder}
          {...inputProps}
        />

        <Button
          _disabled={{ opacity: 1, cursor: "not-allowed" }}
          disabled={!search || search === ""}
          h="full"
          left={buttonLeft ? "0" : "unset"}
          pl={buttonLeft ? 6 : "unset"}
          position="absolute"
          pr={buttonLeft ? "unset" : 6}
          right={buttonLeft ? "unset" : "0"}
          top="calc(100% - 52px)"
          type="submit"
          variant="reset"
          {...buttonProps}
        >
          <CustomIcon
            name="magnifier"
            sx={{
              path: {
                fill: "grey.600",
              },
            }}
          />
        </Button>
      </form>
    </Box>
  );
};

export default SearchInput;
