import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, ButtonGroup, Heading, Link, VStack } from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { FC } from "react";
import semanticColors from "src/@chakra-ui/gatsby-plugin/foundations/colors/semanticColors";
import { CustomIcon, MotionBox } from "src/atoms";

const DatoCmsSource: FC<Gatsby.DatoCmsSourceFragment> = ({ title, links }) => {
  if (!links || links.length === 0) {
    return null;
  }

  return (
    <VStack
      align="start"
      mx="auto"
      my={{ base: 6, lg: 20 }}
      spacing="6"
    >
      <Accordion allowToggle textAlign="left" width="100%">
        <AccordionItem
          borderColor="grey.600"
        >
          {({ isExpanded }) => (
            <>
              <ButtonGroup
                sx={{
                  ".close-icon": {
                    path: {
                      fill: "text.primary",
                    },
                  },
                  ".open-icon": {
                    path: {
                      stroke: "grey.600",
                    },
                  },
                  ":hover": {
                    ".open-icon": {
                      path: {
                        stroke: "text.primary",
                      },
                    },
                  },
                }}
                w="full"
              >
                <AccordionButton
                  _focus={{
                    boxShadow: `0 0 0 1px ${semanticColors.text.primary}`,
                  }}
                  px="0"
                  py="6"
                >
                  <Box flex="1" textAlign="left">
                    <Heading
                      _groupFocus={{ color: "text.primary" }}
                      _groupHover={{ color: "text.primary" }}
                      as="h3"
                      color={isExpanded ? "text.primary" : "grey.600"}
                      fontSize="font-20"
                      fontWeight="semibold"
                      lineHeight="shortest"
                      transitionDuration="fast"
                      transitionProperty="color"
                      transitionTimingFunction="ease-out"
                    >
                      {title}
                    </Heading>
                  </Box>

                  <Box h="6" ml={6} w="6">
                    <AnimatePresence>
                      <MotionBox
                        animate={{ opacity: 1, rotate: 0 }}
                        exit={{ display: "none" }}
                        initial={{ opacity: 0, rotate: 60 }}
                        key={isExpanded ? "closeBlueLg" : "plus"}
                      >
                        <CustomIcon
                          className={
                            isExpanded ? "close-icon" : "open-icon"
                          }
                          h="auto"
                          name={isExpanded ? "closeBlueLg" : "plus"}
                          w="auto"
                        />
                      </MotionBox>
                    </AnimatePresence>
                  </Box>
                </AccordionButton>
              </ButtonGroup>

              <AccordionPanel
                display="flex"
                flexDirection="column"
                gridGap="4"
                px="0"
              >
                {links.map(({ label, url }) => (
                  <Link
                    alignItems="center"
                    display="flex"
                    gridGap="2"
                    href={url}
                    key={url}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                  >
                    <CustomIcon
                      name="link"
                      sx={{
                        color: "grey.700",
                      }}
                    />

                    <Box
                      color="grey.700"
                      fontSize="font-20"
                      fontWeight="normal"
                      lineHeight="tall"
                    >
                      {label || url}
                    </Box>
                  </Link>
                ))}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </VStack>
  );
};

export default DatoCmsSource;
